<template>
	<b-modal id="confirm-update-recurrence" centered hide-header hide-footer @hidden="confirmUpdateThis(false)">
		<div class="new-alert">
			<div class="title-alert">
				<v-close class="close-svg" @click="confirmUpdateThis(false)"/>
				<img src="/icons/modal-alert-info.svg" height="90" alt="" />
				Importante!
			</div>

			<p>Deseja editar somente está conta ou todas?</p>
			<p>Caso selecione todas, esta e futuras contas serão alteradas.</p>

			<!--<h4>{{ description }}</h4>-->

			<div class="actions">
				<b-button class="cancel-button" variant="outline-primary" @click="confirmUpdateAll(true)">
					Todas as contas
				</b-button>

				<b-button variant="primary" @click="confirmUpdateThis(true)">
					Somente esta
				</b-button>
			</div>

			<b-button variant="link" @click="confirmUpdateThis(false)">Voltar</b-button>
		</div>
	</b-modal>
</template>

<script>
import Close from "@/assets/icons/close.svg";

export default {
	name: "ConfirmUpdateBillToPayModal",

	components: {
		'v-close': Close,
	},

	props: {
		description: String,
		confirmUpdateThis: Function,
		confirmUpdateAll: Function,
		isRecurrence: Boolean,
	},
}
</script>

<style lang="scss">
#confirm-update-recurrence {
	.modal-content {
		top: 50%;
		left: 50%;
		transform: translate(-50%, 0);
		width: 450px;

		.modal-body {
			padding: 0;
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 28px;
			color: var(--type-active);

			.header {
				border-bottom: 1px solid var(--neutral-200);

				.header-content {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin: 24px;

					.close-svg {
						height: 24px;
						width: 24px;
						fill: var(--neutral-500);
					}
				}
			}

			.body {
				padding: 24px;
				color: var(--type-active);
				font-size: 18px;
				font-weight: 600;

				.description {
					text-transform: uppercase;
					color: var(--blue-500);
				}
			}

			.actions {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: end;
				padding: 24px;

				button {
					margin-left: 10px !important;
				}

				.cancel-button {
					font-weight: bold !important;
					border-color: transparent !important;
				}
			}

			.new-alert{
				padding: 40px;
				.title-alert{
					display: block;
					text-align: center;
					font-size: 26px;
					margin-bottom: 20px;
					color: #0C1D59;

					.close-svg {
						height: 24px;
						width: 24px;
						fill: var(--neutral-500);
						position: absolute;
						right: 20px;
						top: 20px;
					}

					img{
						margin: 0 auto;
						display: block;
						margin-bottom: 20px;
					}
				}

				p{
					text-align: center;
					font-size: 14.5px;
					margin-bottom: 0;
					line-height: 23px;
				}

				h4{
					text-align: center;
					font-size: 20px;
					font-weight: 700;
					margin: 20px 0;
				}

				.actions{
					padding: 0;
					margin-top: 20px;
					display: block !important;
					text-align: center;
					.btn{
						display: inline-block;
						font-size: 16px;
						padding: 8px 30px;
						margin: 0 5px !important;
					}

					.btn-outline-primary{
						border: 2px solid #0E3DE2 !important;
						color: #0E3DE2;
					}
				}

				.btn-link{
					font-size: 14.5px;
					display: block;
					margin: 0 auto;
					margin-top: 20px;
				}
			}
		}
	}
}
</style>
